import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 28,
  },
  path: {
    fill: '#7df3e1',
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <svg className={classes.svg} viewBox="0 0 337.46 428.5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className={classes.path} d="M72 36C72 55.8823 55.8823 72 36 72C16.1177 72 0 55.8823 0 36C0 16.1177 16.1177 0 36 0C55.8823 0 72 16.1177 72 36Z" fill="#6E5FFB"/>
      <path className={classes.path} d="M25.4218 43.5003L35.0618 35.7452L25.4218 27.9762L16.9993 21.3711C16.8886 21.2821 16.7545 21.2263 16.6127 21.2102C16.4709 21.1942 16.3274 21.2186 16.1992 21.2806C16.071 21.3426 15.9635 21.4396 15.8894 21.5601C15.8153 21.6805 15.7777 21.8194 15.781 21.9604V50.3645C15.7797 50.4448 15.8015 50.5238 15.844 50.5924C15.8864 50.6609 15.9478 50.716 16.0208 50.7514C16.0939 50.7867 16.1756 50.8008 16.2564 50.7919C16.3373 50.783 16.4139 50.7516 16.4773 50.7013L25.4218 43.5003Z" fill="white"/>
      <path className={classes.path} d="M55.7259 50.2997V34.215L45.0984 42.7733L54.9251 50.6858C54.9979 50.7437 55.0859 50.78 55.1788 50.7903C55.2717 50.8006 55.3657 50.7846 55.4497 50.7441C55.5337 50.7036 55.6043 50.6403 55.6533 50.5616C55.7022 50.4829 55.7274 50.3921 55.7259 50.2997Z" fill="white"/>
      <path className={classes.path} d="M36.8377 48.0409L55.7259 33.0061V21.8128C55.7282 21.7 55.6978 21.5889 55.6383 21.4925C55.5789 21.3962 55.4927 21.3186 55.3901 21.2689C55.2875 21.2193 55.1727 21.1995 55.0592 21.2121C54.9456 21.2247 54.838 21.269 54.7491 21.3399L28.2443 42.5053L35.1207 48.0409C35.3629 48.2367 35.6662 48.3437 35.9792 48.3437C36.2922 48.3437 36.5956 48.2367 36.8377 48.0409Z" fill="white"/>
    </svg>
  );
};

export default LogoIcon;
